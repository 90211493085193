document.addEventListener('DOMContentLoaded', function () {
  /* Mobile menu */
  var menu_button = document.querySelector('.menu_button--open')
  var menu_button_close = document.querySelector('.menu_button--close')
  var menu = document.querySelector('.block-menu')

  // open
  menu_button.addEventListener('click', function () {
    menu.classList.add('block-menu--open')
    // not needed because menu is not sticky
    // localStorage.setItem("scroll_pos", document.body.scrollTop);
    document.body.classList.add('noscroll')
  })

  // close
  menu_button_close.addEventListener('click', function () {
    menu.classList.remove('block-menu--open')
    menu.classList.remove('init')
    document.body.classList.remove('noscroll')
    // not needed because menu is not sticky
    // var scroll_pos = localStorage.getItem("scroll_pos");
    // if(scroll_pos !== null) {
    //   document.body.scrollTop = scroll_pos;
    // }
  })

  var search_button = document.querySelector('.search_button--open')
  var search_button_close = document.querySelector('.search_button--close')
  var search = document.querySelector('.search-form--mobile')
  var logo = document.querySelector('.block-header .logo')

  search_button.addEventListener('click', function () {
    search.classList.add('search-form--mobile--open')
    logo.classList.add('logo--hide')
  })

  search_button_close.addEventListener('click', function () {
    search.classList.remove('search-form--mobile--open')
    search.classList.remove('init')
    logo.classList.remove('logo--hide')
  })

  /* Panic button (loverboys only so far) */
  var panic_button = document.querySelector('.panic_button')
  if (panic_button) {
    panic_button.addEventListener('click', function () {
      /* The loverboys page is replaced in the browser history,
      so the back button returns to the page before it*/
      window.location.replace('http://nu.nl')
    })
  }

  /* Prevent "before-forward" browser cache, to not get back to an open menu state on mobile */
  var open_mobile_menu = document.querySelector('.block-menu--open')
  window.addEventListener('pageshow', function (event) {
    if (event.persisted && open_mobile_menu !== null) {
      open_mobile_menu.dispatchEvent('click')
    }
  })

  var emptyCheck = function (event) {
    if (this.query.value == '') event.preventDefault()
  }

  // Searchbox precheck
  var forms = document.querySelectorAll('form[class*="search-"]')
  for (var i = 0; i < forms.length; ++i) {
    forms[i].addEventListener('submit', emptyCheck, true)
  }

  // open external links in new window
  var links = document.querySelectorAll(
    'a[href^="http://"]:not([href^="' +
      location.hostname +
      '"]), a[href^="https://"]:not([href^="' +
      location.hostname +
      '"])',
  )
  for (var i = 0; i < links.length; ++i) {
    var link = links[i]
    links[i].setAttribute('target', '_blank')
  }
})
